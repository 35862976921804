<template>
  <div>
    <s-header :name="'地址管理'"></s-header>
    <van-address-list
        v-model="chosenAddressId"
        :list="list"
        default-tag-text="默认"
        @add="onAdd"
        @edit="onEdit"
        @select="onChoose"
    />
  </div>
</template>

<script>
import { setLocal } from '@/common/js/utils'
import sHeader from '@/components/SimpleHeader'
import {useRouter} from 'vue-router'
import {onMounted, reactive, ref, toRefs} from 'vue';
import {getAddress} from "../../../service/product";

export default {
  components: {
    sHeader
  },
  name: "site",
  setup() {
    const router = useRouter()
    const chosenAddressId = ref('1');
    const state = reactive({
      // 地址列表
      list: [],
    })
    onMounted(async () => {
      const {data} = await getAddress(1)
      data.forEach(item => {
        state.list.push({
          id: item.id,
          name: item.realname,
          tel: item.mobile,
          address: item.address_name + item.address_details,
          isDefault: item.is_default == 1 ? true : false,
        })

      })
    })

    const onAdd = () => router.push({path: '/good/addSite', query: {type: "add"}});
    const onEdit = item => {
      router.push({path: '/good/addSite', query: {type: "edit", id: item.id}})
    };
    function onChoose(e) {
      setLocal('addressVal', JSON.stringify(e))
      router.back()
    }
    return {
      onAdd,
      onEdit,
      onChoose,
      chosenAddressId,
      ...toRefs(state)
    };
  },
}
</script>

<style scoped lang="less">
  ::v-deep .van-address-item__edit{
    position: absolute;
    font-size: var(--van-address-list-edit-icon-size);
  }
</style>
